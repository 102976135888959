import { UseTable } from '@ultra/share/components/UTable/useTable';
import { apiUsdkPageConf } from '@/api/usdk';
import { useAppStore } from '@/store/modules/app';
export default (() => {
  const filter = ref({
    cid: undefined
  });
  const columns = [{
    label: '渠道ID',
    prop: 'cid'
  }, {
    label: '渠道名称',
    prop: 'channelName'
  }, {
    label: '帮助中心',
    prop: 'help',
    slot: 'help'
  }, {
    label: '切换账号',
    prop: 'account',
    slot: 'account'
  }, {
    label: '个人中心',
    prop: 'user',
    slot: 'user'
  }, {
    label: '账号注销',
    prop: 'loginout',
    slot: 'loginout'
  }, {
    label: '操作',
    action: true
  }];
  const gameInfo = computed(() => useAppStore().gameInfo);
  const getTableData = async () => {
    tableLoading.value = true;
    const res = await apiUsdkPageConf({
      gid: gameInfo.value.Id,
      ...filter.value
    });
    if (res.code === 0) {
      tableData.value = res.data;
      console.log(res);
    }
    tableLoading.value = false;
  };
  const {
    tableData,
    tableLoading
  } = UseTable(getTableData);
  return {
    filter,
    columns,
    getTableData,
    tableLoading,
    tableData
  };
});